import { useState } from "react";
import useApi from "../state/api";

interface ProductEditProps {
  productName: string;
  productId: string;
  link: string;
  onClose: () => void;
}

const ProductEdit: React.FC<ProductEditProps> = ({
  productName,
  productId,
  link,
  onClose,
}) => {
  const [url, setUrl] = useState(link);

  const { setProductCompetitorUrl } = useApi();

  const save = function () {
    setProductCompetitorUrl(productId.toString(), url, link);
  };

  return (
    <div className="popup">
      <div className="popup-frame">
        <div className="popup-close" onClick={onClose}></div>

        <div className="popup-headline">Editace URL produktu</div>
        <table className="popup-table">
          <tbody>
            <tr className="tableheader">
              <td className="thfirst thfirst-np"></td>
              <td className="">Model</td>
              <td>URL</td>
              <td className="thlast thlast-np"></td>
            </tr>

            <tr className="settings-row">
              <td></td>
              <td className="b">{productName}</td>
              <td className="popup-tdlast" colSpan={2}>
                <input
                  type="text"
                  className="popup-input"
                  value={url}
                  onChange={(e) => {
                    setUrl(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      save();
                      onClose();
                    }
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="popup-control">
          <button
            className="button button-bold"
            onClick={() => {
              save();
              onClose();
            }}
          >
            Uložit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductEdit;
