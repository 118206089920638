import { useState } from "react";
import useApi from "../state/api";

function Login() {
  const { login, status } = useApi();
  const [password, setPassword] = useState("");

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log("Calling login with password", password);
    login(password);
  };

  return (
    <div className="login">
      <div>
        <img
          src="/logo_guruwatch.svg"
          alt="Guru Watch"
          className="header-logo"
        />
      </div>
      <div className="login-frame">
        <div className="login-headline">Please sign in</div>
        <form onSubmit={handleSubmit} className="login-form">
          <label className="login-label">
            <span className="login-label-text">Password&nbsp;*</span>
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={
                "login-input " +
                (status === "Neplatné heslo" ? "login-input-error" : "")
              }
            />
            {status === "Neplatné heslo" && (
              <span className="login-label-error-text">
                Chybně zadané heslo
              </span>
            )}
          </label>
          <button
            className="button button-wide"
            type="submit"
            form="form"
            value="Sign in"
            onClick={handleSubmit}
          >
            Sign in
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
