import React, { useCallback, useState } from "react";
import useApi, {
  ApiState,
  CompetitorDetail,
  PriceHistory,
  Product,
  Project,
} from "../state/api";
import PriceHistoryRow from "../components/PriceHistoryRow";

import ProductEdit from "../components/Popup";

import { currency, getPriceClass } from "../lib/format";
import Header from "../components/Header";
import Footer from "../components/Footer";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // nezapomeňte importovat CSS
import InlineTdEdit from "../components/InlineTdEdit";
import TdDoubleClick from "../components/tdDoubleClick";

interface rowDetailInterface {
  [key: string]: boolean;
}

interface productEditInterface {
  productName: string;
  productId: string;
  link: string;
}

const PricingInTheMarket: React.FC = () => {
  const { data, getProductPriceHistory, setProductPrice }: ApiState = useApi();
  // const data: { [key: string]: any } = useApi();

  const [rowDetail, setRowDetail] = useState<rowDetailInterface>({});

  const [productEditFlag, setProductEditFlag] = useState<Boolean>(false);
  const [productEditData, setProductEditData] =
    useState<productEditInterface | null>(null);

  const pathSegments = window.location.pathname.split("/");
  let project = pathSegments[2]; // get the project name from the URL

  let projectData = data[project] as Project;

  projectData["products"].forEach((product) => {
    if (product.category1 === null || product.category1 === undefined) {
      product.category1 = "";
    }

    if (product.category2 === null || product.category2 === undefined) {
      product.category2 = "";
    }
  });

  projectData["products"].sort((a, b) => {
    // První porovnání podle category1
    if (a.category1 < b.category1) {
      return -1;
    }
    if (a.category1 > b.category1) {
      return 1;
    }

    // Pokud je category1 stejná, porovnejte category2
    if (a.category2 < b.category2) {
      return -1;
    }
    if (a.category2 > b.category2) {
      return 1;
    }

    // Pokud je i category2 stejná, porovnejte name (od A do Z)
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }

    return 0;
  });

  const [PriceHistoryData, setPriceHistoryData] = useState<PriceHistory>({});

  const setDetail = useCallback(
    async (id: string) => {
      if (!(id in rowDetail) || rowDetail[id] === false) {
        let dat = await getProductPriceHistory(id);
        setPriceHistoryData((prevState) => ({ ...prevState, [id]: dat }));

        setRowDetail((prevState) => ({
          ...prevState,
          [id]: true,
        }));
      } else {
        setPriceHistoryData((prevState) => {
          const newState = { ...prevState };
          delete newState[id];
          return newState;
        });

        setRowDetail((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }
    },
    [rowDetail, getProductPriceHistory, setPriceHistoryData, setRowDetail]
  );

  const competitorsName =
    projectData && "competitors" in projectData
      ? Object.keys(projectData["competitors"]).sort()
      : [];

  let odd = false;

  return (
    <React.Fragment>
      <Header />
      <div className="table-wrapper table-sticky3cols">
        <div className="table-side-gradient"></div>

        <table>
          {/* table header start */}
          <tbody>
            <tr className="tableheader tableheader-sticky">
              <td className="thfirst table-sticky3cols-child table-sticky3cols-first table-sticky3cols-width-50"></td>
              <td className="table-sticky3cols-child table-sticky3cols-second table-sticky3cols-width-100 table-sticky3cols-left-50 table-sticky3cols-header">
                Category 1
              </td>
              <td className="table-sticky3cols-child table-sticky3cols-third table-sticky3cols-width-150 table-sticky3cols-left-150 table-sticky3cols-header">
                Category 2
              </td>
              <td className="table-sticky3cols-child table-sticky3cols-fourth table-sticky3cols-width-400 table-sticky3cols-left-300 table-sticky3cols-header">
                Model
              </td>
              <td className="currency">RRP</td>

              {competitorsName &&
                competitorsName.map((competitor) => {
                  // console.log("Konkurence", competitor);
                  if (competitor.indexOf(".") > 0) {
                    competitor = competitor.substring(
                      0,
                      competitor.indexOf(".")
                    );
                  }
                  return (
                    <td key={competitor + "name"} className="currency">
                      {competitor}
                    </td>
                  );
                })}

              <td className="thlast"></td>
            </tr>

            {data &&
              projectData &&
              projectData["products"].map((product: Product) => {
                //console.log("Map over product", product);
                odd = !odd;
                return (
                  <React.Fragment key={product._id}>
                    <tr
                      className={
                        "mainrow " +
                        (odd ? "bgwhite" : "bgblue") +
                        (product._id in rowDetail && rowDetail[product._id]
                          ? " expander-lines"
                          : "")
                      }
                      key={product._id}
                    >
                      <td className="table-sticky3cols-child table-sticky3cols-first table-sticky3cols-width-50"></td>
                      <td className="table-sticky3cols-child table-sticky3cols-second table-sticky3cols-width-100 table-sticky3cols-left-50 b">
                        {product.category1}
                      </td>
                      <td className="table-sticky3cols-child table-sticky3cols-third table-sticky3cols-width-150 table-sticky3cols-left-150 b">
                        {product.category2}
                      </td>
                      <td
                        className={
                          "b " +
                          (product._id in rowDetail && rowDetail[product._id]
                            ? " expander-active"
                            : "") +
                          " expander "
                        }
                        onClick={() => {
                          setDetail(product._id);
                        }}
                      >
                        {product["name"]}
                      </td>

                      <InlineTdEdit
                        tdClass="b currency"
                        inputClass="td-edit"
                        EditValue={currency(
                          product["price"],
                          projectData.currency
                        )}
                        onSave={(value) => {
                          console.log(
                            "onSave called",
                            Number(value.replace(",", "."))
                          );
                          setProductPrice(
                            product._id,
                            Number(Number(value.replace(",", ".")))
                          );
                          console.log(
                            "onSave called with number",
                            Number(value.replace(",", "."))
                          );
                        }}
                        onChange={(value) => {
                          return value.replace(/[^0-9,.]/g, "");
                        }}
                        onFormat={(value) => {
                          console.log("onFormat called", value);
                          return currency(
                            value.replace(",", "."),
                            projectData.currency
                          );
                        }}
                      />
                      {competitorsName.map((competitor) => {
                        let comp: CompetitorDetail | null = null;

                        if (
                          product.name in
                          data[project]["competitors"][competitor]
                        ) {
                          comp =
                            data[project]["competitors"][competitor][
                              product.name
                            ];

                          comp.lastUpdate =
                            comp["lastUpdate"].split(" ")[1] +
                            " " +
                            comp["lastUpdate"].split(" ")[0];
                        }

                        if (comp === null) {
                          return (
                            <td
                              key={competitor + product.name}
                              onDoubleClick={() => {
                                console.log("Double click");
                                setProductEditFlag(true);
                                setProductEditData({
                                  productName: product.name,
                                  productId: product._id.toString(),
                                  link: "",
                                });
                              }}
                            ></td>
                          );
                        } else {
                          let url = comp.url;

                          if (url.includes(";")) {
                            url = url.substring(0, url.indexOf(";"));
                          }
                          return (
                            <TdDoubleClick
                              className={
                                (comp !== null && comp.price > 0
                                  ? getPriceClass(product.price, comp.price)
                                  : "") +
                                " priceLink" +
                                " currency"
                              }
                              key={competitor + product.name}
                              onDoubleClick={() => {
                                console.log("onDoubleClick");
                                setProductEditFlag(true);
                                setProductEditData({
                                  productName: product.name,
                                  productId: product._id.toString(),
                                  link: url,
                                });
                              }}
                              onClick={() => {
                                console.log("onClick");
                                window.open(url, "_blank");
                              }}
                            >
                              <Tippy content={comp.lastUpdate}>
                                <span style={{ cursor: "pointer" }}>
                                  {currency(comp.price, projectData.currency)}
                                </span>
                              </Tippy>
                            </TdDoubleClick>
                          );
                        }
                      })}
                      <td></td>
                    </tr>
                    {PriceHistoryData[product._id] && (
                      <PriceHistoryRow
                        PriceHistoryData={PriceHistoryData[product._id]}
                        competitorsName={competitorsName}
                        odd={odd}
                        projectCurrency={projectData.currency}
                      />
                    )}
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
      <Footer />
      {productEditFlag && (
        <ProductEdit
          productName={productEditData?.productName ?? ""}
          productId={productEditData?.productId ?? ""}
          link={productEditData?.link ?? ""}
          onClose={() => {
            console.log("Calling onClose...");
            setProductEditFlag(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default PricingInTheMarket;
