import React from "react";
import { currency, getPriceClass } from "../lib/format";
import useApi, {
  ApiState,
  Product,
  Project,
  competitorData,
} from "../state/api";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Competitors() {
  const { data }: ApiState = useApi();

  const pathSegments = window.location.pathname.split("/");
  let project = pathSegments[2]; // get the project name from the URL

  // Check if the project exists in the data
  if (!(project in data)) {
    // If the project does not exist, use the first project
    project = Object.keys(data)[0];
  }

  let key: number = 0;

  let found = false;

  const projectData = data[project] as Project;

  console.log('projectData["products"].length', projectData["products"].length);

  return (
    <>
      <Header />
      <div className="table-wrapper grid-view">
        {projectData &&
          projectData["products"]
            .sort((a: Product, b: Product) => a.name.localeCompare(b.name))
            .map((product: Product, indexCompetitor: Number) => {
              // console.log("Competitors data", product.competitorData);
              key++;

              if (
                "competitorData" in product &&
                Object.keys(product.competitorData).length > 0
              ) {
                let odd = false;

                let sortedCompetitorData = Object.fromEntries(
                  Object.entries(product.competitorData).sort(
                    (a, b) => a[1].price - b[1].price
                  )
                );

                // console.log("sortedCompetitorData", sortedCompetitorData);

                return (
                  <div className="small-table" key={key + "competitor"}>
                    <div className="small-table-header">
                      {product.competitorUrl && product.competitorUrl !== "" ? (
                        <a
                          href={product.competitorUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>{product.name}</span>
                        </a>
                      ) : (
                        <span>{product.name}</span>
                      )}
                      <span className="small-table-header-price">
                        {currency(product.price, projectData.currency)}
                      </span>
                    </div>
                    <div className="small-table-info">
                      {product.competitorDescription}
                    </div>
                    <table>
                      <tbody>
                        {product.competitorData &&
                          Object.entries(sortedCompetitorData).map(
                            (
                              [name, competitor]: [string, competitorData],
                              index: number
                            ) => {
                              found = true;
                              odd = !odd;
                              return (
                                <tr
                                  className={odd ? "bgwhite" : "bgblue"}
                                  key={name + product._id + index}
                                >
                                  <td className="b st-first">{name}</td>
                                  <td
                                    className={
                                      "b " +
                                      getPriceClass(
                                        product.price,
                                        competitor.price
                                      )
                                    }
                                    // className={
                                    //   "b " +
                                    //   (product.price < competitor.price
                                    //     ? "trendup"
                                    //     : "trenddown")
                                    // }
                                  >
                                    {currency(
                                      competitor.price,
                                      projectData.currency
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  </div>
                );
              } else {
                return (
                  <React.Fragment
                    key={key + "competitor" + product.name}
                  ></React.Fragment>
                );
              }
            })}
      </div>

      {!found && (
        <div className="pageinfo">
          <div className="pageinfo-nodata">
            Customer input data not provided
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}

export default Competitors;
