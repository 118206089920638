import { io } from "socket.io-client";
import { create } from "zustand";

let url = "https://" + window.location.hostname;

if (
  window.location.hostname.includes("localhost") ||
  window.location.hostname.includes("honza.guruwatch.cz")
) {
  url = "http://" + window.location.hostname + ":4000";
} else {
  console.log("Websocket using default url...");
}

const socket = io(url, {
  autoConnect: true,
});

console.log("Connecting to socket url", url);

export interface competitorData {
  price: number;
  url: string;
  stock: boolean;
}

export interface Product {
  _id: string;
  competitorDescription: string | null;
  competitorUrl: string | null;
  name: string;
  price: number;
  currency: string;
  competitorData: competitorData[];
  category1: string;
  category2: string;
}

export interface CompetitorDetail {
  price: number;
  stock: boolean;
  url: string;
  lastUpdate: string;
}

export interface Competitor {
  [key: string]: CompetitorDetail;
}

export interface PriceDetails {
  name: string;
  price: number;
  competitor: string;
  date: string;
  db_date: string;
  [key: string]: string | number;
}

export interface StockDetails {
  newStock: boolean;
  competitor: string;
  createdAt: string;
  [key: string]: string | boolean;
}

export interface StockHistory {
  [key: string]: {
    [competitor: string]: StockDetails;
  };
}

export interface PriceHistory {
  [key: string]: {
    [competitor: string]: PriceDetails;
  };
}

export interface WatchDogLog {
  subject: string;
  added: boolean | null;
  createdAt: string;
  deleted: boolean | null;
  feedId: String;
  newPrice: string;
  oldPrice: string;
  product: string;
  stock: boolean | null;
  firstListing: boolean | null;
  _id: string;
}

interface watchDogLogsItem {
  _id: string;
  domain: String;
  url: string;
  category1: string;
  category2: string;
  oldStock: Boolean | null;
  newStock: Boolean | null;
  added: Boolean;
  deleted: Boolean;
  oldPrice: Number | null;
  newPrice: Number | null;
  createdAt: string;
  name: string;
}

export interface Project {
  name: string;
  _id: string;
  competitors: {
    [key: string]: Competitor;
  };
  currency: string;
  products: Product[];
  watchDogLogs: WatchDogLog[];
  watchDogLogsItems: watchDogLogsItem[];
}

export interface Data {
  [key: string]: Project;
}

export interface ApiState {
  login: (password: string) => void;
  status: string;
  logout: () => void;
  data: Data;
  scrollStatus: boolean;
  getProductPriceHistory: (id: string) => Promise<any>;
  getProductStockHistory: (id: string) => Promise<any>;
  setScroll(scroll: boolean): void;
  footerStatus: boolean;
  setFooterStatus(footerStatus: boolean): void;
  setProductPrice: (id: string, price: number) => Promise<any>;
  setProductCompetitorUrl: (
    id: string,
    url: string,
    oldUrl: string
  ) => Promise<any>;
  forceLoading: boolean;
}

const useApi = create<ApiState>((set) => {
  let passwordToBeVerifed = "";
  const login = async (password: string) => {
    socket.disconnect();
    socket.connect();
    // console.log("Called login inside useApi", password);
    socket.auth = { token: password };
    passwordToBeVerifed = password;
    window.localStorage.setItem("passwd", passwordToBeVerifed);
    // socket.emit("login", { token: password });
    // console.log('    socket.emit("login", { token: ' + password + " });");
  };

  const setScroll = (scroll: boolean) => {
    window.localStorage.setItem("setScroll", scroll ? "true" : "false");
    set({ scrollStatus: scroll });
  };

  const setFooterStatus = (footerStatus: boolean) => {
    console.log("Setting footer status", footerStatus);
    window.localStorage.setItem(
      "footerStatus",
      footerStatus ? "true" : "false"
    );
    set({ footerStatus });
  };

  const logout = () => {
    console.log("Called logout, reseting password...");
    socket.auth = {};
    passwordToBeVerifed = "";

    socket.emit("logout");
    set({ status: "not-logged", data: {} });
    window.localStorage.setItem("passwd", "");
    socket.disconnect();
    socket.connect();
  };

  const getProductPriceHistory = async (id: string) => {
    return await new Promise((resolve, reject) => {
      socket.emit("getProductPriceHistory", id, (data: any) => {
        return resolve(data);
      });
    });
  };

  const getProductStockHistory = async (id: string) => {
    return await new Promise((resolve, reject) => {
      socket.emit("getProductStockHistory", id, (data: any) => {
        return resolve(data);
      });
    });
  };

  const setProductPrice = async (id: string, price: number) => {
    console.log("Setting price", price, "for product", id);
    // set({ forceLoading: true });
    socket.emit("setProductPrice", id, price);

    const data = await new Promise((resolve, reject) => {
      socket.emit("getUpdate", (data: any) => {
        return resolve(data);
      });
    });
    set({ data: data as Data });
    // set({ forceLoading: false });
  };

  const setProductCompetitorUrl = async (
    id: string,
    url: string,
    oldUrl: string
  ) => {
    console.log("Setting url", url, "for product", id);

    socket.emit("setProductCompetitorUrl", id, url, oldUrl);

    // set({ forceLoading: true }); // set loading state....
    const data = await new Promise((resolve, reject) => {
      socket.emit("getUpdate", (data: any) => {
        return resolve(data);
      });
    });
    set({ data: data as Data });
    // set({ forceLoading: false });
  };

  //call login here
  socket.on("connect", () => {
    console.log("Connected to server, emiting login event");
    set({ data: {} });
    socket.emit("login", { token: window.localStorage.getItem("passwd") });
  });

  socket.on("disconnect", () => {
    console.log("Disconnected from server, setting status to disconnected");
    set({ status: "disconnected", data: {} });
  });

  socket.on("connect_error", (err: any) => {
    if (passwordToBeVerifed) {
      set({ status: err.message });
      console.log(err.message); // "Neplatné heslo"
      console.log(err.data); // { content: "Prosím, zkontrolujte své přihlašovací údaje." }
      // } else {
      //   console.log("Setting status to logout, becose of connect_error");
      //   set({ status: "logout", data: {} });
    }
  });

  socket.on("login", async (message: any) => {
    // console.log("Got message", message);

    if (message.status === "ok") {
      set({ status: "logged", data: {} });
      const data = await new Promise(async (resolve, reject) => {
        // console.log("Called getProductPriceHistory", id);
        await new Promise((resolve) => setTimeout(resolve, 500));
        socket.emit("getUpdate", (data: any) => {
          return resolve(data);
        });
      });

      console.log("Got data in login", data);

      set({ data: data as Data });
    } else {
      set({ status: "not-logged" });
    }
  });

  let data: Data = {};

  // socket.on("data", (message: any) => {
  //   console.log("Got data", message);

  //   set({ status: "logged", data: message });
  // });

  socket.auth = { token: window.localStorage.getItem("passwd") };

  setInterval(async () => {
    try {
      const data = await new Promise((resolve, reject) => {
        console.log("Called getUpdate periodicatly");
        socket.emit("getUpdate", (data: any) => {
          return resolve(data);
        });
      });
      set({ data: data as Data });

      console.log("Called getUpdate periodicatly, got data", data);
    } catch (e) {
      console.log("Error in getUpdate", e);
    }
  }, 1000 * 60);

  console.log(
    "Loading footer status",
    window.localStorage.getItem("footerStatus") === "true" ? true : false
  );

  console.log(
    "Loading password",
    window.localStorage.getItem("passwd")
      ? window.localStorage.getItem("passwd")
      : "No password found"
  );

  return {
    login,
    logout,
    status: "connecting",
    data,
    getProductPriceHistory,
    getProductStockHistory,
    scrollStatus:
      window.localStorage.getItem("setScroll") === "true" ? true : false,
    setScroll,
    setFooterStatus,
    footerStatus:
      window.localStorage.getItem("footerStatus") === "true" ? true : false,
    setProductPrice,
    setProductCompetitorUrl,
    forceLoading: false,
  };
});

export default useApi;
