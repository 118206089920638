import { StockDetails } from "../state/api";

interface PriceHistoryRowProps {
  StockHistoryData: { [key: string]: StockDetails };
  competitorsName: string[];
  odd: boolean;
}

export default function PriceHistoryRow({
  StockHistoryData,
  competitorsName,
  odd,
}: PriceHistoryRowProps) {
  console.log("StockHistoryData", StockHistoryData);

  if (Object.keys(StockHistoryData).length === 0) {
    return (
      <tr
        className={
          "detailrow expander-lines" + (odd ? " bgwhite " : " bgblue ")
        }
      >
        <td className="borderblue"></td>
        <td className="borderblue"></td>
        <td className="borderblue"></td>
        <td className="b time">No data</td>
        <td></td>
        {competitorsName.map((competitor) => {
          return <td key={competitor + "detail"}></td>;
        })}
        <td></td>
      </tr>
    );
  } else {
    return (
      <>
        {Object.keys(StockHistoryData).map((key: string, index: number) => {
          const isLast = index === Object.keys(StockHistoryData).length - 1;
          return (
            <tr
              className={
                "detailrow " +
                (odd ? " bgwhite " : " bgblue ") +
                (isLast ? " detailrow-last expander-lines" : "")
              }
              key={"detail" + key}
            >
              <td className="borderblue"></td>
              <td className="borderblue"></td>
              <td className="borderblue"></td>
              <td className="b time">
                <span>{key.split(" ")[0]}</span>
                <span>{key.split(" ")[1]}</span>
              </td>
              <td></td>
              {competitorsName.map((competitor) => {
                let stock: string = "";
                if (competitor in StockHistoryData[key]) {
                  stock =
                    (
                      StockHistoryData[key][
                        competitor
                      ] as unknown as StockDetails
                    ).newstock === true
                      ? "Yes"
                      : "No";
                }

                return <td key={competitor + "detail"}>{stock}</td>;
              })}
              <td></td>
            </tr>
          );
        })}
      </>
    );
  }
}
