import { currency } from "../lib/format";
import { PriceDetails } from "../state/api";

interface PriceHistoryRowProps {
  PriceHistoryData: { [key: string]: PriceDetails };
  competitorsName: string[];
  odd: boolean;
  projectCurrency: string;
}

export default function PriceHistoryRow({
  PriceHistoryData,
  competitorsName,
  odd,
  projectCurrency,
}: PriceHistoryRowProps) {
  if (Object.keys(PriceHistoryData).length === 0) {
    return (
      <tr
        className={
          "detailrow expander-lines" + (odd ? " bgwhite " : " bgblue ")
        }
      >
        <td className="borderblue"></td>
        <td className="borderblue"></td>
        <td className="borderblue"></td>
        <td className="b time">No data</td>
        <td></td>
        {competitorsName.map((competitor) => {
          return <td key={competitor + "detail"}></td>;
        })}
        <td></td>
      </tr>
    );
  } else {
    return (
      <>
        {Object.keys(PriceHistoryData).map((key: string, index: number) => {
          const isLast = index === Object.keys(PriceHistoryData).length - 1;
          return (
            <tr
              className={
                "detailrow " +
                (odd ? " bgwhite " : " bgblue ") +
                (isLast ? " detailrow-last expander-lines" : "")
              }
              key={"detail" + key}
            >
              <td className="borderblue"></td>
              <td className="borderblue"></td>
              <td className="borderblue"></td>
              <td className="b time">
                <span>{key.split(" ")[0]}</span>
                <span>{key.split(" ")[1]}</span>
              </td>
              <td></td>
              {competitorsName.map((competitor) => {
                let price: string = "";
                if (competitor in PriceHistoryData[key]) {
                  price = (
                    PriceHistoryData[key][competitor] as unknown as PriceDetails
                  ).price.toString();
                }

                return (
                  <td key={competitor + "detail"} className="currency">
                    {currency(price, projectCurrency)}
                  </td>
                );
              })}
              <td></td>
            </tr>
          );
        })}
      </>
    );
  }
}
