import { useRef } from "react";

interface TdDoubleClickProps {
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  onDoubleClick?: () => void;
}
const TdDoubleClick: React.FC<TdDoubleClickProps> = ({
  className,
  children,
  onClick,
  onDoubleClick,
}) => {
  const clickTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isDoubleClickRef = useRef<boolean>(false);

  const handleDoubleClick = (event: React.MouseEvent<HTMLTableCellElement>) => {
    event.stopPropagation();
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
      clickTimeoutRef.current = null;
    }
    isDoubleClickRef.current = true;
    if (onDoubleClick) {
      onDoubleClick();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLTableCellElement>) => {
    if (!isDoubleClickRef.current) {
      clickTimeoutRef.current = setTimeout(() => {
        if (!isDoubleClickRef.current) {
          if (onClick) {
            onClick();
          }
        }
        isDoubleClickRef.current = false;
      }, 300);
    } else {
      isDoubleClickRef.current = false;
    }
  };

  return (
    <td
      className={className}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
    >
      {children}
    </td>
  );
};

export default TdDoubleClick;
