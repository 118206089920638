import "./App.css";
import PricingInTheMarket from "./pages/PricingInTheMarket";
import StockInTheMarket from "./pages/StockInTheMarket";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Login from "./pages/Login";
import useApi, { ApiState } from "./state/api";
// import WatchDogLog from "./pages/WatchDogLog";
import Competitors from "./pages/Competitors";
import WatchDogLogItems from "./pages/WatchDogLogItems";

document.title = "Guru dashboard";

const Logged = () => {
  const location = useLocation();

  const { data }: ApiState = useApi();

  const project = Object.keys(data)[0];

  console.log(
    location.pathname.split("/"),
    location.pathname.split("/").length
  );

  if (
    location.pathname.split("/").length === 3 &&
    !(location.pathname.split("/")[2] in data)
  ) {
    return (
      <Routes>
        <Route path="*" element={<Navigate to="/Login" />} />
      </Routes>
    );
  } else if (
    location.pathname.split("/")[2] === "" ||
    location.pathname.split("/").length === 2 ||
    location.pathname.split("/")[1] === "login"
  ) {
    let page =
      location.pathname.split("/")[1] === "" ||
      location.pathname.split("/")[1] === "login"
        ? "PricingInTheMarket"
        : location.pathname.split("/")[1];

    let to = "/" + page + "/" + project;
    console.log("Navigate to", to);
    return (
      <Routes>
        <Route path="*" element={<Navigate to={to} />} />
      </Routes>
    );
  } else {
    console.log("Accepting routes...");
    return (
      <Routes>
        <Route path="/PricingInTheMarket/*" element={<PricingInTheMarket />} />
        <Route path="/StockInTheMarket/*" element={<StockInTheMarket />} />
        <Route path="/Competitors/*" element={<Competitors />} />
        <Route path="/WatchDogLog/*" element={<WatchDogLogItems />} />
        <Route path="/*" element={<PricingInTheMarket />} />
      </Routes>
    );
  }
};

const App: React.FC = () => {
  const { status, data } = useApi();

  console.log(status);

  if (status !== "logged") {
    return (
      <>
        <Login />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>Login status...{status}</h1>
        </div>
      </>
    );
  } else {
    if (Object.keys(data).length === 0) {
      return (
        <div className="login-loading">
          <div className="login-loading-logo">
            <img src="/logo_simple.svg" alt="Guru Watch" />
            <img
              src="/loading.svg"
              alt="Loading"
              className="login-loading-logo-circle"
            />
          </div>
          <div className="login-loading-text">Loading...</div>
        </div>
      );
    } else {
      return (
        <BrowserRouter>
          <Logged />
        </BrowserRouter>
      );
    }
  }
};

export default App;
