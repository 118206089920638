export function formatDate(date: string) {
  return new Date(date).toLocaleDateString("cs-Cs", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

export function formatDateTime(dateString: string): string {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Měsíce jsou indexovány od 0
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}.${month}.${year} ${hours}:${minutes}`;
}

export function currency(number: any, currency: string) {
  if (number) {
    return parseFloat(number).toLocaleString("cs-CZ", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: currency.toLowerCase() === "czk" ? 0 : 2,
    });
  } else {
    return "";
  }
}

export function getPriceClass(price: number, competitorPrice: number) {
  if (competitorPrice === 0 || competitorPrice === null) {
    return "";
  }

  if (price === 0 || price === null) {
    return "";
  }

  if (price / competitorPrice > 1.05) {
    return "b trenddown";
  }

  if (price / competitorPrice < 0.95) {
    return "b trendup";
  }

  return "";
}
