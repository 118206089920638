import { useLocation, useNavigate } from "react-router-dom";
import useApi from "../state/api";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

function Header() {
  const navigate = useNavigate();
  const { data, logout, setScroll, scrollStatus } = useApi();
  const location = useLocation();

  const pathSegments = window.location.pathname.split("/");
  let project = pathSegments[2]; // get the project name from the URL

  const projects = Object.keys(data);

  const urls = useMemo(
    () => [
      {
        page: "PricingInTheMarket",
        text: "Pricing in the market",
      },
      {
        page: "StockInTheMarket",
        text: "Stock in the market",
      },
      {
        page: "Competitors",
        text: "Product competitiveness",
      },
      {
        page: "WatchDogLog",
        text: "Watchdog summary",
      },
    ],
    []
  ); // prázdné pole závislostí znamená, že urls se nezmění

  let currentProjectIndex = useRef<number>(0);
  let currentPageIndex = useRef<number>(0);

  function getComponent() {
    const path = location.pathname.split("/");
    const page = path[1];
    const project = path[2];

    currentPageIndex.current = urls.findIndex((url) => url.page === page);
    currentProjectIndex.current = projects.findIndex((p) => p === project);

    return path[1];
  }

  const nextPage = useCallback(() => {
    // Získání aktuálního indexu
    const currentIndex = currentPageIndex.current;
    const currentProject = currentProjectIndex.current;

    // Výpočet následujícího indexu
    let nextIndex = (currentIndex + 1) % urls.length;

    // Pokud jsme na konci seznamu URL, přejdeme na další projekt
    let nextProject = currentProject;
    if (nextIndex === 0) {
      nextProject = (currentProject + 1) % projects.length;
    }

    // Vrácení cesty
    return `/${urls[nextIndex].page}/${projects[nextProject]}`;
  }, [currentPageIndex, currentProjectIndex, urls, projects]);

  const previousPage = useCallback(() => {
    // Získání aktuálního indexu
    const currentIndex = currentPageIndex.current;
    const currentProject = currentProjectIndex.current;

    // Výpočet předchozího indexu
    let previousIndex = currentIndex > 0 ? currentIndex - 1 : urls.length - 1;

    // Pokud jsme na začátku seznamu URL, přejdeme na předchozí projekt
    let previousProject = currentProject;
    if (currentIndex === 0) {
      previousProject =
        currentProject > 0 ? currentProject - 1 : projects.length - 1;
    }

    // Vrácení cesty
    return `/${urls[previousIndex].page}/${projects[previousProject]}`;
  }, [currentPageIndex, currentProjectIndex, urls, projects]);

  function getCurrentUrlText() {
    let component = getComponent();
    for (let i = 0; i < urls.length; i++) {
      if (urls[i].page === component) {
        return urls[i].text;
      }
    }
    return "";
  }

  let direction = useRef(1); // 1 pro dolů, -1 pro nahoru
  const speed = 1; // rychlost scrollování v pixelech za interval
  let nextPageJump = useRef(new Date().getTime() + 60000);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    // window.scrollTo(0, 0);

    const handleKeyDown = (event: KeyboardEvent) => {
      // Zde můžete přidat podmínku pro konkrétní klávesu, například:
      if (event.key === "p") {
        setScroll(!scrollStatus);
      } else if (event.key === "ArrowRight") {
        console.log("Key press next page...");
        let old = scrollStatus;
        setScroll(false);
        navigate(nextPage());
        window.scrollTo(0, 0);
        setTimeout(() => {
          setScroll(old);
        }, 1000);
      } else if (event.key === "ArrowLeft") {
        console.log("Key press previews page...");
        let old = scrollStatus;
        setScroll(false);
        navigate(previousPage());
        window.scrollTo(0, 0);
        setTimeout(() => {
          setScroll(old);
        }, 1000);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    function autoScroll() {
      if (scrollStatus === false) {
        // console.log("Scoll je vypnuty");
        return;
      }
      // console.log("Called autoScroll");

      // Pokud jsme na konci stránky, změníme směr na nahoru
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        direction.current = -1;
        if (new Date().getTime() > nextPageJump.current) {
          console.log("Going to next page...");
          window.scrollTo(0, 0);
          nextPageJump.current = new Date().getTime() + 60000;
          navigate(nextPage());
        }
      }

      // Pokud jsme na začátku stránky, změníme směr na dolů
      if (window.scrollY === 0) {
        direction.current = 1;
      }

      // Posuneme stránku nahoru nebo dolů
      window.scroll(0, window.scrollY + speed * direction.current);
      // window.scrollTo(0, window.scrollY + speed * direction.current);
    }

    // Nastavíme interval pro autoScroll
    const intervalId = setInterval(autoScroll, 50);

    // Nezapomeňte odstranit posluchače událostí, když komponenta odmontuje
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      clearInterval(intervalId);
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [
    scrollStatus,
    setScroll,
    navigate,
    nextPage,
    previousPage,
    location.pathname,
  ]);

  document.title = "Guru - " + project + ":" + getCurrentUrlText();

  const [menu, setMenu] = useState(false);

  return (
    <div className="header">
      <div>
        <img
          src="/logo_guruwatch.svg"
          alt="Guru Watch"
          className="header-logo"
        />
      </div>
      <div
        className={"header-menu " + (menu ? "header-menu-open" : "")}
        onClick={() => {
          setMenu(!menu);
        }}
      >
        Menu
      </div>
      <div className="pager">
        <div className="pager-arrows">
          <a
            href={nextPage()}
            onClick={(e) => {
              e.preventDefault();
              navigate(previousPage());
            }}
            className="pager-arrowleft"
            aria-label="Previous page"
          ></a>
          <a
            href={nextPage()}
            className="pager-arrowright"
            onClick={(e) => {
              e.preventDefault();
              navigate(nextPage());
            }}
            aria-label="Next page"
          ></a>
        </div>
        <span className="pager-text">
          {project}: {getCurrentUrlText()}
        </span>
      </div>
      <div className={"header-nav " + (menu ? "header-nav-open" : "")}>
        <div>
          <label
            className={`switcher ${
              scrollStatus === true ? "" : "switcher-off"
            }`}
          >
            <input
              type="checkbox"
              className="switcher-input"
              checked={scrollStatus}
              onChange={(e) => {
                setScroll(!scrollStatus);
              }}
            />
            <span className="switcher-in"></span>
            <span className="switcher-text">Scrolling</span>
          </label>
        </div>
        {/* <div className="hlinks">
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate("/productSettings");
            }}
            className="hlink hlink-settings"
          >
            Product settings
          </a>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboardSettings");
            }}
            className="hlink hlink-dashboard"
          >
            Dashboard settings
          </a>
        </div> */}
        <div>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate("/login");
              logout();
            }}
            className="hlink hlink-logout"
          >
            Logout
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
