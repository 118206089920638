import React from "react";
import useApi, { ApiState, Project } from "../state/api";
import { currency, formatDateTime } from "../lib/format";
import Header from "../components/Header";
import Footer from "../components/Footer";

function WatchDogLogItems() {
  const { data }: ApiState = useApi();

  const pathSegments = window.location.pathname.split("/");
  let project = pathSegments[2]; // get the project name from the URL

  const projectData = data[project] as Project;

  let odd = false;
  return (
    <React.Fragment>
      <Header />
      {!projectData || projectData.watchDogLogsItems.length === 0 ? (
        <div className="pageinfo">
          <div className="pageinfo-nodata">
            No data, please wait for the first data to be collected
          </div>
        </div>
      ) : (
        <div className="table-wrapper">
          <div className="table-side-gradient"></div>

          <table>
            {/* table header start */}
            <tbody>
              <tr className="tableheader tableheader-sticky">
                <td className="thfirst"></td>

                <td>Category 1</td>
                <td>Category 2</td>

                <td>Partner</td>
                <td>Product Title</td>

                <td>old Price</td>
                <td>new Price</td>

                <td>old Stock</td>
                <td>new Stock</td>

                <td>Added</td>
                <td>Deleted</td>

                <td>Created at</td>

                <td className="thlast"></td>
              </tr>
              {projectData.watchDogLogsItems &&
                projectData.watchDogLogsItems.map((log) => {
                  odd = !odd;
                  return (
                    <tr
                      key={log._id}
                      className={(odd ? "bgwhite" : "bgblue") + " mainrow"}
                    >
                      <td></td>
                      <td>{log.category1}</td>
                      <td>{log.category2}</td>

                      <td>{log.domain}</td>
                      <td>
                        <a
                          href={log.url}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          {log.name}
                        </a>
                      </td>

                      <td className="currency">
                        {log.oldPrice !== log.newPrice &&
                          currency(log.oldPrice, projectData.currency)}
                      </td>
                      <td
                        className={
                          (log.oldPrice !== log.newPrice &&
                          log.oldPrice &&
                          log.newPrice
                            ? log.oldPrice > log.newPrice
                              ? "b trenddown"
                              : "b trendup"
                            : "") + " currency"
                        }
                      >
                        {log.oldPrice !== log.newPrice &&
                          currency(log.newPrice, projectData.currency)}
                      </td>

                      <td>
                        {log.oldStock !== log.newStock &&
                          (log.oldStock === true ? "Yes" : "No")}
                      </td>
                      <td
                        className={
                          log.oldStock === true
                            ? "textcolor-red"
                            : "textcolor-green"
                        }
                      >
                        {log.oldStock !== log.newStock &&
                          (log.newStock === true ? "Yes" : "No")}
                      </td>

                      <td
                        className={
                          log.added ? "textcolor-green" : "textcolor-red"
                        }
                      >
                        {log.added === true && "Yes"}
                      </td>
                      <td
                        className={
                          log.deleted ? "textcolor-green" : "textcolor-red"
                        }
                      >
                        {log.deleted === true && "Yes"}
                      </td>

                      <td>{formatDateTime(log.createdAt)}</td>
                      <td></td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}

      <Footer />
    </React.Fragment>
  );
}

export default WatchDogLogItems;
