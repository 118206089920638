import { useEffect, useRef, useState } from "react";

interface InlineTdEditProps {
  tdClass?: string;
  inputClass?: string;
  EditValue: string;
  onSave: (value: string) => void;
  onChange?: (value: string) => string;
  onFormat?: (value: string) => string;
}

const InlineTdEdit: React.FC<InlineTdEditProps> = ({
  tdClass,
  inputClass,
  EditValue,
  onSave,
  onChange,
  onFormat,
}) => {
  const [EditFlag, setEditFlag] = useState<Boolean>(false);
  const [EditInputValue, setEditInputValue] = useState<string>(
    onChange ? onChange(EditValue) : EditValue
  );
  const [printValue, setPrintValue] = useState<string>(EditValue);

  const inputRef = useRef<HTMLInputElement>(null);

  const performSave = () => {
    if (EditInputValue) {
      onSave(EditInputValue);
      onFormat
        ? setPrintValue(onFormat(EditInputValue))
        : setPrintValue(EditInputValue);
    }
  };

  useEffect(() => {
    if (EditFlag && inputRef.current) {
      inputRef.current.focus();
    }
  }, [EditFlag]);

  return (
    <td
      className={tdClass}
      onDoubleClick={() => {
        setEditFlag(true);
        setEditInputValue(onChange ? onChange(EditValue) : EditValue);
      }}
    >
      {EditFlag ? (
        <input
          ref={inputRef}
          className={inputClass}
          type="text"
          value={EditInputValue}
          onChange={(e) => {
            if (onChange) {
              setEditInputValue(onChange(e.target.value));
            } else {
              setEditInputValue(e.target.value);
            }
          }}
          onBlur={() => {
            console.log("onBlur called");
            setEditFlag(false);
            performSave();
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              console.log("Enter key pressed");
              setEditFlag(false);
              performSave();
            } else if (e.key === "Escape") {
              console.log("Escape key pressed");
              setEditFlag(false);
              onFormat
                ? setPrintValue(onFormat(EditInputValue))
                : setPrintValue(EditInputValue);
              setEditInputValue(EditValue);
            }
          }}
        />
      ) : (
        <>{printValue}</>
      )}
    </td>
  );
};

export default InlineTdEdit;
