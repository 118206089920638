import useApi from "../state/api";

export default function Footer() {
  const { footerStatus, setFooterStatus } = useApi();
  return footerStatus ? (
    <div className="footer">
      <div className="key-set">
        <div className="key">P</div>
        <div>
          Press <b>“P”</b> scrolling on/off
        </div>
      </div>
      <div className="key-set">
        <div className="key key-sec">Arrow left</div>
        <div>
          Press <b>“Arrow left”</b> for previous screen
        </div>
      </div>
      <div className="key-set">
        <div className="key key-sec">Arrow right</div>
        <div>
          Press <b>“Arrow right”</b> for next screen
        </div>
      </div>
      <div>
        <button className="button" onClick={() => setFooterStatus(false)}>
          Hide
        </button>
      </div>
    </div>
  ) : (
    <div className="footer-tab" onClick={() => setFooterStatus(true)}></div>
  );
}
